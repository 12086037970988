.pb {
    margin-left: 24px;
    overflow: auto;
    .pbCompany {
        padding: 1rem;
        box-shadow: 0px 4px 4px 0px var(--orange-100) inset;
        display: flex;
        gap: 1rem;
        > legend {
            display: none;
        }
    }
    .pbList {
        .pbActions {
            justify-content: end;
        }
        .pbTable {
            padding-left: 0;
            padding-right: 0;

            > table {
                margin: 0;
            }
        }
    }
    .pbUpdate {
        flex-wrap: wrap;
        gap: 1rem;

        .select {
            width: 100%;
            max-width: unset;
            > * {
                font-size: 14px;
            }
        }

        article:has(.select) {
            width: calc((100% * 4) / 12 - 1rem);
            min-width: 200px;
            align-items: start;
            flex-basis: auto;
            flex-grow: 1;
        }

        .textfield {
            width: 100%;
            max-width: unset;
            padding: 22px;
        }

        div:has(> .textfield) {
            width: calc((100% * 4) / 12 - 1rem);
            min-width: 200px;
            flex-basis: auto;
            flex-grow: 1;
        }

        .checkbox {
            width: calc((100% * 4) / 12 - 1rem);
            min-width: 200px;
            flex-basis: auto;
            flex-grow: 1;
        }
    }
    .pbUpdateActions {
        flex-wrap: wrap;
        justify-content: end;
    }
}
